<template>
  <div class="contaier">
    <div class="part-title">统计方式</div>
    <template v-if="$route.query.examType == 1">
      <div class="title">区域统计设置</div>
      <div class="mb18">
        <div v-for="(item, index) in data.areaNames" :key="index" class="row">
          <div class="row-label">{{ item }}</div>
        </div>
      </div>
      <div class="row wrap">
        <div class="row-label">不参与统计的学校：</div>
        <div
          v-for="(item, index) in schoolIncludes"
          :key="index"
          class="row-text"
          style="margin-right: 12px"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="$route.query.examType == 1" class="row">
        <div class="row-label">是否生成班级相关统计：</div>
        <div class="row-text">{{ data.statClass == 0 ? "否" : "是" }}</div>
      </div>
      <div class="row">
        <div class="row-label">统计基数设置：</div>
        <div class="row-text">
          {{ data.baseType == 1 ? "按参统条件统计" : "固定统计人数" }}
        </div>
      </div>
      <div v-if="data.baseType == 1" class="row mb18">
        <div class="row-label">零分统计设置：</div>
        <div class="row-text">
          {{ data.excludeZero == 1 ? "零分不参统" : "零分参统" }}
        </div>
      </div>
    </template>
    <div class="part-title">统计参数</div>
    <div v-if="$route.query.ruleId > 0" class="row">
      <div class="row-label">统计类型：</div>
      <div v-if="scoreType == 0" class="row-text">以原始分统计</div>
      <div v-if="scoreType == 1" class="row-text">以赋分统计</div>
    </div>
    <template v-if="$route.query.examType != 1">
      <div class="row">
        <div class="row-label">统计方式：</div>
        <div v-if="data.baseType === 1" class="row-text">
          去掉不参与统计的人
        </div>
        <div v-if="data.baseType === 2" class="row-text">固定统计人数</div>
      </div>
      <div v-if="data.baseType !== 2" class="row">
        <div class="row-label">零分统计情况：</div>
        <div v-if="data.excludeZero === 1" class="row-text">零分不参与统计</div>
      </div>
    </template>
    <div class="title">统计参数设置</div>
    <div v-if="$route.query.ruleId == 0 || scoreType == 0" class="row mb18">
      <div class="row-label">前N名区间：</div>
      <div class="row-text">{{ data.topSection }}</div>
    </div>
    <div
      v-if="$route.query.ruleId > 0 && scoreType == 1"
      class="row align-start"
    >
      <div class="row-label">前N名区间：</div>
      <div>
        <div
          v-for="(item, index) in data.topSections.slice(
            1,
            data.topSections.length
          )"
          :key="index"
          class="flex-item lineHeight28"
        >
          <div class="row-text row-margin-left">
            {{ getSubjectValue("name", item.subjectId) }}：
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="title">分数段区间</div>
    <div v-if="data.statSegmentSettings" class="row align-start">
      <div>
        <div
          v-for="(item, index) in data.statSegmentSettings"
          :key="index"
          class="flex-item lineHeight28"
        >
          <div class="row-text row-margin-left">
            {{ getSubjectValue("name", item.subjectId) }}：
            {{ getSubjectValue("score", item.subjectId) }}
          </div>
          <div class="row-text row-margin-left">上限：{{ item.maximum }}</div>
          <div class="row-text row-margin-left">下限：{{ item.minimum }}</div>
          <div class="row-text row-margin-left">间隔：{{ item.gap }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="$route.query.ruleId == 0 && $route.query.examType != 1"
      class="row align-start"
    >
      <div class="row-label">排名区间设置：</div>
      <div>
        <div class="flex-item lineHeight28">
          <div class="row-text row-margin-left">上限{{ data.rankMaximum }}</div>
          <div class="row-text row-margin-left">下限{{ data.rankMinimum }}</div>
          <div class="row-text row-margin-left">间隔{{ data.rankGap }}</div>
        </div>
      </div>
    </div>
    <div class="title">自定义比率设置</div>
    <div class="row">
      <div class="row-label">
        {{ data.ratioType == 1 ? "按分数比例" : "按人数比例" }}：
      </div>
      <div
        v-for="(item, index) in data.statRatioSettings"
        :key="index"
        class="row-text"
      >
        {{ item.name }}率（{{ getRatioType(item.type) }} {{ item.value }}%）
      </div>
    </div>
    <div class="row">
      <div class="row-label">参与全科比率统计的科目：</div>
      <div
        v-for="(item, index) in data.allSubjectRatioSubjectIds"
        :key="index"
        class="row-text"
      >
        {{ getSubjectValue("name", item) }}
        <span v-if="index !== data.allSubjectRatioSubjectIds.length - 1"
          >、</span
        >
      </div>
    </div>
    <template v-if="$route.query.examType == 1">
      <div class="row">
        <div class="row-label">联考端学生排名：</div>
        <div class="row-text">
          {{ data.isUnionDisplayStudentRank == 1 ? "是" : "否" }}
        </div>
      </div>
      <!-- <div class="row">
        <div class="row-label">学校端学生排名：</div>
        <div class="row-text">
          {{ data.isSchoolDisplayStudentRank == 1 ? "是" : "否" }}
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="row-label">学校端学生成绩：</div>
        <div class="row-text">
          {{ data.isSchoolDisplayStudentScore == 1 ? "是" : "否" }}
        </div>
      </div> -->
    </template>

    <template v-if="$route.query.ruleId == 0 && $route.query.scoreType != 1">
      <div class="title">等级区间设置</div>
      <div class="title">{{ levelSegmentTypeEnmu[data.levelSegmentType] }}</div>
      <div class="row column">
        <div
          v-for="(item, index) in data.statLevelSegmentSettings"
          :key="index"
          class="row-text lineHeight28"
        >
          {{ getSubjectValue("name", item.subjectId) }} 等级名称:
          {{ item.name || "-" }} 等级百分比: {{ item.value || "-" }}
        </div>
      </div>
    </template>
    <div
      v-if="
        ($route.query.ruleId == 0 || scoreType == 0) &&
        $route.query.examType != 1
      "
      class="row align-start"
    >
      <div class="row-label">上线设置：</div>
      <div>
        <div
          v-if="data.onlineType === 1"
          class="row-text lineHeight28"
          style="padding-left: 12px"
        >
          按比例设置
        </div>
        <div
          v-if="data.onlineType === 2"
          class="row-text lineHeight28"
          style="padding-left: 12px"
        >
          按人数设置
        </div>
        <div
          v-if="data.onlineType === 3"
          class="row-text lineHeight28"
          style="padding-left: 12px"
        >
          按有效分设置
        </div>
        <div class="row column">
          <div
            v-for="(item, index) in data.statOnlineSettings"
            :key="index"
            class="row-text lineHeight28"
          >
            {{ item.name }} {{ item.value }}
            <span v-if="data.onlineType === 1">%</span>
            <span v-if="data.onlineType === 2">名</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        ($route.query.ruleId == 0 || scoreType == 0) &&
        $route.query.examType != 1
      "
      class="row align-start"
    >
      <div class="row-label">临界区间：</div>
      <div class="row column">
        <div
          v-for="(item, index) in data.statCriticalIntervalSettings"
          :key="index"
          class="row-text lineHeight28"
        >
          {{ getSubjectValue("name", item.subjectId) }} {{ item.value }}分
        </div>
      </div>
    </div>
    <div class="row">
      <div class="row-label red">学生答题卡打印设置：</div>
      <div class="row-inline">
        <div v-if="data.sheetPrintScore" class="row-text row-margin-left">
          总分分值
        </div>
        <div
          v-if="data.sheetPrintQuestionScore"
          class="row-text row-margin-left"
        >
          小题分值
        </div>
        <div v-if="data.sheetPrintTeacher" class="row-text row-margin-left">
          阅卷老师
        </div>
        <div v-if="data.sheetPrintNotes" class="row-text row-margin-left">
          批注信息
        </div>
        <div v-if="data.sheetPrintObjectNotes" class="row-text row-margin-left">
          客观题批注信息
        </div>
      </div>
    </div>
    <template v-if="$route.query.examType != 1">
      <div class="row">
        <div class="row-label red">学生排名是否显示：</div>
        <div class="row-text">
          {{ data.isDisplayRank ? "显示排名" : "不显示排名" }}
        </div>
      </div>
      <!-- <div class="row">
      <div class="row-label red">成绩条排名设置：</div>
      <div class="row-text">显示排名</div>
    </div> -->
      <div class="row">
        <div class="row-label red">成绩榜排序设置：</div>
        <div v-if="data.rankListOrder === 1" class="row-text">按排名排序</div>
        <div v-if="data.rankListOrder === 2" class="row-text">按考号排序</div>
        <div v-if="data.rankListOrder === 3" class="row-text">按姓名排序</div>
        <div v-if="data.rankListOrder === 4" class="row-text">随机排序</div>
      </div>
      <div class="row">
        <div class="row-label red">对比考试设置：</div>
        <div v-if="compareList.length" class="row-text">
          {{ getexamName(data.compareId) }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="title">学生答题卡打印设置：</div>
      <div class="row">
        <div class="row-label">总分分值：</div>
        <div class="row-text">
          {{ data.sheetPrintScore == 1 ? "显示" : "不显示" }}
        </div>
      </div>
      <div class="row">
        <div class="row-label">小题分值：</div>
        <div class="row-text">
          {{ data.sheetPrintQuestionScore == 1 ? "显示" : "不显示" }}
        </div>
      </div>
      <div class="row">
        <div class="row-label">阅卷教师：</div>
        <div class="row-text">
          {{ data.sheetPrintTeacher == 1 ? "显示" : "不显示" }}
        </div>
      </div>
      <div class="row">
        <div class="row-label">打分批注：</div>
        <div class="row-text">
          {{ data.sheetPrintNotes == 1 ? "显示" : "不显示" }}
        </div>
      </div>
      <div class="row">
        <div class="row-label">客观题批注：</div>
        <div class="row-text">
          {{ data.sheetPrintObjectNotes == 1 ? "显示" : "不显示" }}
        </div>
      </div>
      <div class="title">学校代码设置：</div>
      <div class="row wrap">
        <div
          v-for="(item, index) in data.schoolCodes"
          :key="index"
          class="row-text"
        >
          {{ item.name }}<span v-if="item.code">（{{ item.code }}）</span>;
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getstatschoolcomparelist } from "@/core/api/academic/common";
// import { getstatcomparelist } from "@/core/api/academic/statistics";
import { getstatscoretypesetting } from "@/core/api/academic/newExam";
export default {
  name: "StatModel",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statSubjects: [],
      compareList: [],
      scoreType: null,
      schoolIncludes: [],
      levelSegmentTypeEnmu: {
        1: "按分数比例",
        2: "按人数比例",
        3: "按分数",
        4: "按人数",
      },
    };
  },
  activated() {
    this.initData();
  },
  mounted() {
    this.getstatscoretypesettingData();
  },
  methods: {
    initData() {
      const {
        statSubjects,
        stat: { level, year, examId },
      } = this.data;
      this.statSubjects = statSubjects;
      if (this.$route.query.examType == 1) {
        this.schoolIncludes = this.data.schoolIncludes.filter(
          (item) => item.isInclude === 0
        );
      }
      if (level && year & examId) {
        this.getStatCompareList(level, year);
      }
    },
    async getstatscoretypesettingData() {
      const res = await getstatscoretypesetting({
        id: this.$route.query.id,
      });
      this.scoreType = res.data.data;
      this.initData();
    },
    getexamName(id) {
      if (id) {
        const data = this.compareList.find((item) => item.id === id);
        if (data) {
          return data.name;
        } else {
          return "-";
        }
      }
    },
    async getStatCompareList(level, year) {
      const res = await getstatschoolcomparelist({
        examLevel: level,
        examYear: year,
        statId: this.$route.query.id,
      });
      this.compareList = res.data.data;
    },
    getSubjectValue(field, id) {
      if (id === 0) {
        let data = {
          name: "总分",
          score: this.data.stat.score,
        };
        return data[field];
      }
      const data = this.statSubjects.find((item) => item.subjectId === id);
      return data[field];
    },
    getRatioType(type) {
      if (this.data.ratioType === 1) {
        return (type === 1 && "分数≥") || (type === 2 && "分数＜");
      } else if (this.data.ratioType === 2) {
        return (type === 1 && "人数前") || (type === 2 && "人数后");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contaier {
  .row {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: 26px;

    &-label {
      font-size: 14px;
      color: #333;
      white-space: nowrap;
      font-weight: 500;
    }

    &-text {
      font-size: 14px;
      white-space: nowrap;
      color: #333;
      font-weight: 300;
    }

    &-inline {
      display: flex;
      align-items: center;
      margin-right: 18px;
    }

    .row-margin-left + .row-margin-left {
      margin-left: 5px;
    }
  }
  .wrap {
    flex-wrap: wrap;
  }
  .align-start {
    align-items: flex-start;
  }

  .column {
    flex-direction: column;
    align-items: flex-start;
  }

  .bottom24 {
    margin-bottom: 24px;
  }

  .red {
    color: #ff0000;
  }
}
.part-title {
  color: #2474ed;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.title {
  font-size: 14px;
  color: #0a1119;
  box-sizing: border-box;
  margin: 8px 0;
  font-weight: 600;
}

.flex-item {
  display: flex;
  align-items: center;
  // margin-bottom: 24px;
}

.lineHeight28 {
  line-height: 28px;
}

.align-start {
  align-items: flex-start;
}

.mb18 {
  margin-bottom: 18px;
}
</style>
